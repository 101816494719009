import ManagerSidebar from "../layout/sidebar/ManagerSidebar";

export default function WfmIframe() {
    const wfmURL = process.env.REACT_APP_WFM_URL;
    
    return (
        <>
            <ManagerSidebar />
            <div className="profileMain">
                <div className="layout-outlet workforce-container">
                    <iframe
                        title="wfm"
                        width="100%"
                        height="100%"
                        src={wfmURL}
                        style={{ border: "none" }}
                    />
                </div>
            </div>
        </>
    )
}
