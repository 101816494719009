import "./AdminSettings.css";
import TextField from '@mui/material/TextField';
import { get,post } from "../api/apiCalls";
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import { useMemo, useRef, useState } from "react";
import Switch from "react-switch";
import AdminHeaderLinks from "../layout/header/AdminHeaderLinks"
import { Toast } from "primereact/toast";
import { getSettings, postSettings } from "../api/env";
function AdminSettings () {
    const toast = useRef(null)
    let [settings,setSettings] = useState(null);

    const showInfo = (text) => {
        toast.current.show({severity:'success', summary: 'Success', detail:text, life: 3000});
    }


    let saveSettings = async () => {
        let keys = {
            settingName : "SSO",
            value : JSON.stringify(settings)
        }
        console.log("VALUES: ", keys)
        await post(postSettings,keys);
        showInfo("Settings Saved")
    }

    let listSettings = async () => {
        let settings = JSON.parse((await get(getSettings,{settingName : "sso"})).value);
        setSettings(settings);
       
    }

    useMemo(async () => {
        if(settings==null)
            listSettings();
      },[]);
    return (
   
    <div className="settingsContainer">
        <AdminHeaderLinks/>
        <Toast ref={toast} position="top-center"/>
        { settings && 
            <Box sx={{ flexGrow: 1 }} className="settingsBody">
                <Grid container spacing={2}>
                    <Grid xs={10}>
                        <h3 className="settingsLabel">SSO settings</h3>
                    </Grid>
                    
                    <Grid xs={6}>
                    <TextField
                        id="outlined-controlled"
                        label="Keycloak URL"
                        defaultValue={settings.keycloakURL}
                        className="txtField"
                        onChange={(e) => setSettings({...settings,keycloakURL : e.target.value})}
                    />
                    </Grid>
                    <Grid xs={6} />

                    <Grid xs={6}>
                    <TextField
                        id="outlined-controlled"
                        label="SSO Redirect URL"
                        defaultValue={settings.ssoRedirectUrl}
                        className="txtField"
                        onChange={(e) => setSettings({...settings,ssoRedirectUrl : e.target.value})}
                    />
                    </Grid>
                    <Grid xs={6} />

                    <Grid xs={6}>
                    <TextField
                        id="outlined-controlled"
                        label="realm"
                        defaultValue={settings.realm}
                        className="txtField"
                        onChange={(e) => setSettings({...settings,realm : e.target.value})}
                    />
                    </Grid>
                    <Grid xs={6} />

                    <Grid xs={6}>
                    <TextField 
                        id="outlined-controlled"
                        label="Client Id"
                        defaultValue={settings.clientID}
                        className="txtField"
                        minRows={5}
                        onChange={(e) => setSettings({...settings,clientID : e.target.value})}

                    />
                    </Grid>
                    <Grid xs={6} />
                    <Grid xs={6} >
                        <div>
                            <label htmlFor="Show SSO" className="ssoSwitchLabel" >Enable SSO</label>
                            <Switch  checked={settings.showSSO} 
                                onChange={(e) => setSettings({...settings,showSSO : e})}
                            />
                        </div>
                        <div>
                            <label htmlFor="ByPass" className="ssoSwitchLabel" >Bypass Login</label>
                            <Switch  checked={settings.byPass} 
                                onChange={(e) => setSettings({...settings,byPass : e})}
                            />
                        </div>
                    </Grid>
                    <Grid xs={6} />
                    <Grid xs={6}>
                    <button className="saveBtn" onClick={() => saveSettings()}>Save Settings</button>
                
                    </Grid>
                </Grid>
            </Box>
        }
    </div>);
}

export default AdminSettings;