import { Outlet } from "react-router-dom";
import Header from "./header/Header";
import './layouts.css'
import ManagerSidebar from "./sidebar/ManagerSidebar";

export function AdminLayout(){
   
    return (
        <div>
            <Header title='Wisdom Dashboard'/>
            <div className="layout-container">
                <ManagerSidebar />
                <div className="layout-outlet">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}