import SearchSection from "../../ui/SearchSection";
import './UserDailyDiary.css'
import ManagerSubHeaderLinks from "../../layout/header/ManagerSubHeaderLinks";
import { useParams } from "react-router-dom";
import React, { useState, useEffect, useRef} from 'react';
import moment from 'moment';
import { fetchImageFromAPI, get, post, put } from "../../api/apiCalls";
import "./Diary.css";
import DateSelector from "../../ui/DateSelector";
import TimezoneSelector from "../../ui/TimezoneSelector";
import DiaryTimeLine from "../../elements/diaryElements/DiaryTimeline";
import { ReactSession } from 'react-client-session'
import LoadingSpinner from "../../elements/UiElements/LoadingSpinner";
import { baseURL, getProjectForAgent, getTrackingData, getTrackingDataProfilePicture, postUserById, putTrackingDataAudit } from "../../api/env";
import UserShorProfile from "../../elements/summaryElements/UserShorProfile";
import { Toast } from "primereact/toast";
import DefaultProfileImage from '../../../images/default.jpg'
import { Dialog } from "primereact/dialog";
import { Dropdown } from 'primereact/dropdown';

function UserDailyDiary(props) {
  const toast = useRef(null)
  const params = useParams();
  let [timeOffset,setTimeOffset] = useState(0);
  let [timecards, setTimecards] = useState([]);
  let [summary, setSummary] = useState([]);
  let [loading,setLoading] = useState(true);
  let [selectedDiaryDate,setSelectedDiaryDate] = useState(moment().format("YYYY-MM-DD"));
  let [projects, setProjects] = useState([])
  let [euser,setEuser] = useState(0);
  let [connect,setConnect] = useState(0);
  let[loadingImages, setLoadingImages]= useState(true);
  const [disableUploadButton, setDisableUploadButton] = useState(true)
  const [visible, setVisible] = useState(false)
  const [dialogVisible, setDialogVisible] = useState(false)
  const [loggedUser, setLoggedUser] = useState()
  const [selectedStatus, setSelectedStatus] = useState(null)
  const [selectedProject, setSelectedProject] = useState(null)

  const user = ReactSession.get('User')
  const cardStatus = [
    {id: 0, name: 'Productive'},
    {id: 4, name: 'Marked'},
    {id: 6, name: 'Rejected'}
  ]
  const setDiaryDate = (date) => {
    setSelectedDiaryDate(moment(date).format("YYYY-MM-DD"));
  };

  const showInfo = (text) => {
    toast.current.show({severity:'success', summary: 'Success', detail:text, life: 3000});
  }

  const getSummaryDetails = async (offset) => {
		const keys = {
		  date: moment(selectedDiaryDate).format("YYYY-MM-DD"),
		  id: parseInt(params.employeeId),
      offset
		};
	  
		let summary = await fetchSummaryData(keys);
		if (summary.length <= 0) {
      setSummary([])
		} else {
      setSummary(summary);
		}
    setLoadingImages(false)
    setLoading(false)
    setDisableUploadButton(false)
	};
	const fetchSummaryData = async (keys) => {    
		try {
		  const response = await get(getTrackingData, keys);
		  return response;
		} catch (error) {
		  console.error("Error fetching summary data:", error);
		  return undefined;
		}
	};
  
  const handleUpdateCards = async() => {
    setDialogVisible(!dialogVisible)
    let checked = document.querySelectorAll('input:checked');
    let num = [];
    for (const item of checked) {
      num.push(parseInt(item.id));     
      item.checked = true;
    }
    setTimecards(num)
  }

  let changeTimeOffset = (Offset) => {
    timeOffset = Offset
    getSummaryDetails(timeOffset);
  }
  
  const getUserProjects = async() => {
    let key = {
      UserId: params.employeeId
    }
    let list = []
    let response = await get(getProjectForAgent+"?UserId="+key.UserId)
    response.forEach(e => {
      let model = {
        id: 0,
        name:''
      }
      model.id = e.id
      model.name = e.name
      list.push(model)
    });
    setProjects(list)
  }
      
  const uncheckTimeCards = () => {
    let checked = document.querySelectorAll('input:checked');
    for (const item of checked) {
      item.checked = false;
    }
    setTimecards([])
  }

  const handleSubmitUpdateTimeCard = async() => {
    setDialogVisible(!dialogVisible)
    uncheckTimeCards()
    let keys = {
      cardsId: timecards,
      projectId: selectedProject !== null ? selectedProject.id : null,
      projectName: selectedProject !== null ? selectedProject.name: null,
      changedBy: user.username,
      status: selectedStatus !== null ? selectedStatus.id : null
    }

    let response = await put(putTrackingDataAudit, keys)
    console.log(response.status)
    showInfo(response)
    setSelectedProject(null)
    setSelectedStatus(null)
    getSummaryDetails();
  }   

  const getUser = async () => {
    let keys = {
      id : parseInt(params.employeeId)
    };
    let u = await post(postUserById,keys);
    u.image = u.image === null ? DefaultProfileImage : await fetchImageFromAPI(`${baseURL}${getTrackingDataProfilePicture}?ImagePath=${u.image}`)
 
    setEuser(u);
    setLoading(false)
  }

   if(connect==0){
    setConnect(1);
    getUser();
  }

  const handleSelection = () => {
    console.log("Card selecionado")
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        
        const results = await Promise.allSettled([
          getUserProjects(),
          getSummaryDetails(),
          getUser(),
        ]);
  
        results.forEach((result, index) => {
          if (result.status === "rejected") {
            console.error(`Error in function ${index + 1}:`, result.reason);
          }
        });
      } catch (error) {
        console.error("ERROR: ", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [selectedDiaryDate]);
  
  

  return (
    <div className="summary">
      <Toast ref={toast} position="top-center"/>
      <SearchSection />
      {loading && <LoadingSpinner />}
      {!loading && (
        <div className="summaryMain">
          <div className="my-dashboard-header">
          <UserShorProfile
            employeeName={euser?.firstName + ' ' + euser?.lastname || 'N/A'}
            employeePronoun={euser?.pronoun || 'N/A'}
            employeeDesignation={euser?.jobDescription || 'N/A'}
            employeeID={euser?.id || 'N/A'}
            employeeUsername={euser?.username || 'N/A'}
            employeeLocation={euser?.city || 'N/A'}
            employeePicture={euser?.image || DefaultProfileImage}
            employeeDisabled={euser?.disabled || false}
            employeeContractEndDate={euser?.contract_endDate || 'N/A'}
          />
            <ManagerSubHeaderLinks id={params.employeeId}/> 
          </div>
          <div className="diaryMain">
            <div className="filters">
              <span>Diary</span>
              <DateSelector placeholderText={selectedDiaryDate}  updateTimers={setDiaryDate} />
              <span>Timezone</span>
              <TimezoneSelector setTimeOffset={changeTimeOffset} value="Etc/GMT" />
              {loading && <LoadingSpinner />}
            </div>
            <button
              className="timecards-action-btns"
              id="update-cards"
              onClick={handleUpdateCards}
              disabled={disableUploadButton}
            >
              Update Timecard
            </button>
            <button
              style={{marginLeft:'8px'}}
              className="timecards-action-btns"
              id="timecard-status"
              onClick={() => setVisible(!visible)}
            >
              Timecard Status
            </button>
            {summary.length <= 0 && !loading && (
                <p className="no-data-msg">No timecards for the selected day</p>
            )}
            <DiaryTimeLine
              data={summary}
              handleSelection={handleSelection}
            />
          </div>
        </div>

      )}

      <Dialog 
      header="Update Timecards" 
      visible={dialogVisible} 
      style={{ width: '50vw' }} 
      onHide={() => {
        setSelectedProject(null)
        setSelectedStatus(null)
        if (!dialogVisible) return; 
        setDialogVisible(false); 
      }}
      >
        <div>
          <div className="card flex justify-content-center">
              <Dropdown value={selectedProject} onChange={(e) => setSelectedProject(e.value)} options={projects} optionLabel="name" 
                  placeholder="Select a Project" className="w-full md:w-14rem" />
          </div>
          <div className="card flex justify-content-center mt-2">
              <Dropdown value={selectedStatus} onChange={(e) => setSelectedStatus(e.value)} options={cardStatus} optionLabel="name" 
                  placeholder="Select a Status" className="w-full md:w-14rem" />
          </div>
        </div>
        <button className="ok-dialog-btn" onClick={handleSubmitUpdateTimeCard} disabled={!selectedProject && !selectedStatus}>Save</button>
          
      </Dialog>    

      <Dialog 
      header="Timecards Status" 
      visible={visible} 
      style={{ width: '50vw' }} 
      onHide={() => {if (!visible) return; setVisible(false); }}
      >
        <div>
          <table className="timecard-dialog">
            <thead className="timecard-dialog">
                <tr className="timecard-dialog">
                    <th className="timecard-dialog">Status</th>
                    <th className="timecard-dialog">Description</th>
                    <th className="timecard-dialog">Added to Payroll</th>
                </tr>
            </thead>
            <tbody className="timecard-dialog">
              <tr className="timecard-dialog">
                <td className="timecard-dialog">Idle</td>
                <td className="timecard-dialog">Idle timecard is when the tracker did not identify any activity.</td>
                <td className="timecard-dialog">No</td>
              </tr>
              <tr className="timecard-dialog">
                <td className="timecard-dialog">Productive</td>
                <td className="timecard-dialog">A productive and valid timecard</td>
                <td className="timecard-dialog">Yes</td>
              </tr>
              <tr className="timecard-dialog">
                <td className="timecard-dialog">Manual Time</td>
                <td className="timecard-dialog">A timecard created after MT was approved</td>
                <td className="timecard-dialog">Yes</td>
              </tr>
              <tr className="timecard-dialog">
                <td className="timecard-dialog">Marked</td>
                <td className="timecard-dialog">An idle timecard marked as not idle by the user. Example: the user was in a meeting or watching a training.</td>
                <td className="timecard-dialog">Yes</td>
              </tr>
              <tr className="timecard-dialog">
                <td className="timecard-dialog">Rejected</td>
                <td className="timecard-dialog">A timecard that has been rejected by the manager </td>
                <td className="timecard-dialog">No</td>
              </tr>
            </tbody>
          </table>
        </div>
        <button className="ok-dialog-btn" onClick={() => setVisible(!visible)}>Ok</button>
          
      </Dialog>      
    </div>
  );
}

export default UserDailyDiary;
