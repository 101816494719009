import './Notifications.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { notification } from '../../api/env';
import { putRequest } from '../../api/notificationApi';

function Notifications({ messages }) {
    
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [unreadNotifications, setUnreadNotifications] = useState(0);
    const [localMessages, setLocalMessages] = useState(messages); 

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const isDisabled = localMessages.length <= 0;

    async function handleNotificationClick(id, path) {
        try {
            await putRequest(`${notification}`, id);
            
            setLocalMessages(prevMessages => prevMessages.filter(message => message._id !== id));

            if (path) {
                navigate(path);
            }
        } catch (error) {
            console.error("Failed to handle notification click:", error);
        }
    }

    useEffect(() => {
        setLocalMessages(messages);
    }, [messages]);

    useEffect(() => {
        const unreadCount = localMessages.filter(message => !message.isRead).length;
        setUnreadNotifications(unreadCount);
    }, [localMessages]);

    return (
        <div className={`notification-container ${isDisabled ? 'disabled' : ''}`}>
            <div className="notification-icon" onClick={toggleDropdown}>
                <NotificationsIcon fontSize='large' color='inherit'/>
                <span className="notification-count">{unreadNotifications}</span>
            </div>
            {isOpen && (
                <div className="notification-dropdown">
                    <ul className="notification-list">
                        {localMessages
                            .filter(message => !message.isRead)
                            .map(message => (
                                <li key={message._id}>
                                    <button 
                                        onClick={() => handleNotificationClick(message._id, message.path)}
                                        disabled={message.path === ''}
                                    >
                                        {message.content}
                                    </button>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            )}
        </div>
    );
}

export default Notifications;
