import React, { useState, useEffect } from 'react';
import TextInput from '../../ui/TextInput.js';
import { get, put } from "../../api/apiCalls";
import Switch from "react-switch";
import './ProjectsModal.css';
import { getUsersManagers, putProjects } from '../../api/env.js';


function ProjectsModal(props) {
  console.log("PROPS: ", props)
  const [myData, setMyData] = useState(props.modalData); // Current form data
  const [originalData, setOriginalData] = useState(props.modalData); // Original form data for comparison
  const [disabled, setDisabled] = useState(myData.disabled);
  const [mobileTracker, setMobileTracker] = useState(myData.mobileTracker);
  const [sharedService, setSharedService] = useState(myData.shared);
  const [managers, setManagers] = useState([]);
  const [manager, setManager] = useState(myData.manager);
  const [hasChanges, setHasChanges] = useState(false);

  // Function to toggle 'disabled' field
  const toggleOnlyActive = () => {
    const newDisabled = !disabled;
    setDisabled(newDisabled);
    setMyData({ ...myData, disabled: newDisabled });
  };

  // Function to toggle 'mobileApp' field
  const toggleMobileTracker = () => {
    const newDisabled = !mobileTracker;
    setMobileTracker(newDisabled);
    setMyData({ ...myData, mobileTracker: newDisabled });
  };

  // Function to toggle 'shared' field
  const toggleSharedService = () => {
    const newShared = !sharedService;
    setSharedService(newShared);
    setMyData({ ...myData, shared: newShared });
  };

  // Function to handle manager selection
  const handleManagerChange = (event) => {
    const selected = event.target.value.split(" - ");
    setMyData({ ...myData, managerId: selected[0], manager: selected[1] });
  };

  // Function to update project details
  const updateProjectDetails = async () => {
    const keys = {
      ...myData,
      disabled: disabled,
      shared: sharedService,
      mobileTracker: mobileTracker,
    };
    console.log("KEYS: ", keys)
    await put(putProjects, keys);
    setOriginalData(myData); 
    setHasChanges(false); 
    props.saveChanges();
  };

  useEffect(() => {
    const getManagers = async () => {
      const response = await get(getUsersManagers);
      const filteredManagers = response.filter(m => `${m.firstName} ${m.lastName}` !== myData.manager);
      setManagers(filteredManagers);
    };
    getManagers();

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        props.onClose();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [props.onClose]);

  // Effect to check for changes
  useEffect(() => {
    const isDifferent = JSON.stringify(myData) !== JSON.stringify(originalData);
    setHasChanges(isDifferent);
  }, [myData, originalData]);

  return (
    <div className='form-modal project-details-modal' id='project-form-modal'>
      <div id='project-form'>
        <div className='project-details-card'>
          <div className='project-headers'>Project Configuration</div>
          <div className='project-card'>
            <div className='project-card-config'>
              <TextInput
                type='text'
                required='required'
                text='Project Name'
                id='project-name'
                value={myData.project}
                onChangeText={(value) => setMyData({ ...myData, project: value })}
              />
              <TextInput
                type='text'
                required='required'
                text='Contact Name'
                id='project-contact'
                value={myData.contact}
                onChangeText={(value) => setMyData({ ...myData, contact: value })}
              />
              <TextInput
                type='number'
                required='required'
                id='project-hours'
                value={myData.hours}
                onChangeText={(value) => setMyData({ ...myData, hours: value })}
                text='Hour Cap Per Agent'
              />
              <div className='manager-select-box'>
                <label htmlFor='manager-selector'>Manager</label>
                <select id='manager-selector' className="manager-selector" onChange={handleManagerChange}>
                  <option value={manager}>{manager}</option>
                  {managers.map((manager) => (
                    <option key={manager.id} value={`${manager.id} - ${manager.firstName} ${manager.lastName}`}>
                      {manager.firstName} {manager.lastName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='project-card-compliance-config'>
              <TextInput
                type='number'
                required='required'
                id='max-keystrokes'
                value={myData.maxKeystrokes}
                onChangeText={(value) => setMyData({ ...myData, maxKeystrokes: value })}
                text='Max Keystrokes'
              />
              <TextInput
                type='number'
                required='required'
                id='max-mouseclicks'
                value={myData.maxMouseclicks}
                onChangeText={(value) => setMyData({ ...myData, maxMouseclicks: value })}
                text='Max Mouse Clicks'
              />
              <TextInput
                type='number'
                required='required'
                id='max-repeting-keystrokes'
                value={myData.maxRepetingKeystrokes}
                onChangeText={(value) => setMyData({ ...myData, maxRepetingKeystrokes: value })}
                text='Max Repeting Keystrokes'
              />
              <TextInput
                type='number'
                required='required'
                id='max-repeting-mouseclicks'
                value={myData.maxRepetingMouseclicks}
                onChangeText={(value) => setMyData({ ...myData, maxRepetingMouseclicks: value })}
                text='Max Repeting Mouse Clicks'
              />
            </div>
            <div className='project-card-extra-config'>
              <div className="disable-btn">
                <label htmlFor="project-details-onlyActive">Disable</label>
                <Switch onChange={toggleOnlyActive} checked={disabled} />
              </div>
              <div className='project-details-input-with-label'>
                <label htmlFor='is-shared-project'>Shared</label>
                <input
                  type='checkbox'
                  id='is-shared-project'
                  checked={sharedService}
                  onChange={toggleSharedService}
                />
              </div>
              <div className="disable-btn">
                <label htmlFor="project-details-mobileTracker">MobileApp</label>
                <Switch onChange={toggleMobileTracker} checked={mobileTracker} />
              </div>
            </div>
          </div>
        </div>
        <div className='project-card-btns'>
          <button
            className='update-project-button'
            onClick={updateProjectDetails}
            disabled={!hasChanges}
          >
            Save Changes
          </button>
          <button
            className='close-modal-button'
            onClick={props.onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default ProjectsModal;



