import WisdomLogo from "../../images/Wisdom logo transparent.png";
import "./SignedPages.css";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { loginCall, get } from "../api/apiCalls";
import { ReactSession } from 'react-client-session';
import { useLocation } from "react-router-dom";
import Keycloak from "keycloak-js";
import LoadingSpinner from "../elements/UiElements/LoadingSpinner";
import { Toast } from "primereact/toast";
import { getSettings, postUsersLogin } from "../api/env";
import { FaUser } from "react-icons/fa";

function SignIn() {
  const [showAdminLogin, setShowAdminLogin] = useState(false);
  const toast = useRef(null);
  const [session, setSession] = useState();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [authenticated, setAuthenticated] = useState(false);
  const [loadSSO, setLoadSSO] = useState(null);
  const [enableSso, setEnableSso] = useState(false);
  const [isLoading, setIsLoading] = useState(true); 
  const { hash } = useLocation();
  ReactSession.setStoreType("sessionStorage");

  const showError = (text) => {
    toast.current.show({ severity: 'error', summary: 'Error', detail: text, life: 3000 });
  };

  const login = async (ssoEmail, ssoAuth, isSso) => {
    setIsLoading(true); 
    let keys = {};
    if (isSso) {
      keys = { "email": ssoEmail, "password": '', "sso": ssoAuth };
    } else {
      keys = { "email": email, "password": password, "sso": false };
    }

    try {
      ReactSession.remove("User");
      await loginCall(postUsersLogin, keys, true);
      ReactSession.set("TrackerStatus", false);
      ReactSession.set("timeSpentInSeconds", 0);
      localStorage.setItem("LastUpdate", '');
      let user = ReactSession.get("User");
      if (typeof user == 'undefined') {
        showError('Wrong username or password');
      } else {
        window.location.href = user.project != null ? "/team" : "/summary";
      }
    } catch (error) {
      setSession("Wrong username or password");
      console.error(error);
    } finally {
      setIsLoading(false); 
    }
  };

  const sso = async () => {
    setIsLoading(true); 
    setEnableSso(true);
    await initKeyCloak();
  };

  const initKeyCloak = async () => {
    let settings = JSON.parse((await get(getSettings, { settingName: "sso" })).value);
    let keycloak = new Keycloak({
      url: settings.keycloakURL,
      realm: settings.realm,
      clientId: settings.clientID,
    });

    await keycloak.init({
      onLoad: settings.byPass ? 'check-sso' : 'login-required',
      redirectUri: settings.ssoRedirectUrl,
      enableLogging: true,
      useNonce: false,
    }).then(async auth => {
      if (auth && keycloak.idTokenParsed !== null) {
        setAuthenticated(auth);
        setEmail(keycloak.idTokenParsed.email);
        await login(keycloak.idTokenParsed.email, auth, true);
      } else if (settings.byPass) {
        keycloak.login();
      }
    }).finally(() => setIsLoading(false)); 
};


  useMemo(async () => {
    if (hash !== "") {
      initKeyCloak();
    }
    if (loadSSO == null) {
      let settings = JSON.parse((await get(getSettings, { settingName: "sso" })).value);
      setLoadSSO(settings.showSSO);
    }
    setIsLoading(false); 
  }, []);

  useEffect(() => {
    if (password !== '' || email !== '') {
      const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          login();
        }
      };
      window.addEventListener('keydown', handleKeyDown);
      return () => window.removeEventListener('keydown', handleKeyDown);
    }
  }, [login]);

  return (
    <div>
      <Toast ref={toast} position="top-center" />
      {isLoading && (
        <div className="loading-overlay">
          <LoadingSpinner />
        </div>
      )} 
      {loadSSO !== null && (
        <div className="login-main-container">
          <div className="floating-login-container">
            <img className="wisdom-logo-img" src={WisdomLogo} alt="Wisdom Logo" />
            <hr className="divider top-divider" />
            <div className="signed-header">Log in using your account on</div>
            <div className="sign-in-page-section">
              <div className="signIn-btns">
                <button className="signIn-sso-btn" onClick={sso} disabled={isLoading}>
                  <FaUser color="white" /> Login with Sirius Email
                </button>
                <hr className="divider" />
                <div className="first-time-here-tip">
                  <p className="signed-header">Is your first time here?</p>
                  <p>Use the button above to login and provide your email credentials.</p>
                </div>
                <hr className="divider" />
                <button className="signIn-login-btn" onClick={() => setShowAdminLogin(!showAdminLogin)} disabled={isLoading}>
                  Admin Login
                </button>
                {showAdminLogin && (
                  <>
                    <input className="login-page-input-text" type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} disabled={isLoading} />
                    <input className="login-page-input-text" type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} disabled={isLoading} />
                    <button className="signIn-login-btn" onClick={login} disabled={isLoading}>Login</button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <footer className="login-page-footer">
        <div>
          <a href="https://sirius-support.com/terms-of-service/" target="_blank">Terms of Service</a> | <a href="https://sirius-support.com/privacy-policy/" target="_blank">Privacy Policy</a>
        </div>
        <div>
          <p>&copy; 2025 <a href='https://sirius-support.com' target="_blank">Sirius Support</a></p>
        </div>
      </footer>
    </div>
  );
}

export default SignIn;
