import SignIn from "../components/signedpages/SignIn";

function SignInPage() {
  return (
    <div className="signedpages">
      <SignIn />
    </div>
  );
}

export default SignInPage;
