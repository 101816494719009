export const baseURL = process.env.REACT_APP_BASE_URL;
export const notificationBaseURL = process.env.REACT_APP_NOTIFICATION_BASE_URL;
export const hubURL = process.env.REACT_APP_HUB_URL;
export const versionNumber = process.env.REACT_APP_VERSION_NUMBER;
export const ssoRedirectURL = process.env.REACT_APP_SSO_REDIRECT_URL;

//#region ANNOUNCEMENTS
export const getAnnoucements = process.env.REACT_APP_GET_ANNOUNCEMENTS
export const putAnnouncements = process.env.REACT_APP_PUT_ANNOUNCEMENTS
//#endregion

//#region COMPLIANCE
export const getCompliance = process.env.REACT_APP_GET_COMPLIANCE
export const putCompliance = process.env.REACT_APP_PUT_COMPLIANCE
export const getComplianceSettings = process.env.REACT_APP_GET_COMPLIANCESETTINGS
export const putComplianceSettings = process.env.REACT_APP_PUT_COMPLIANCESETTINGS
//#endregion

//#region DESKTOPAPP
export const getDesktopApp = process.env.REACT_APP_GET_DESKTOPAPP
export const getDesktopAppUserStatus = process.env.REACT_APP_GET_STATUS_DESKTOPAPP
export const getDesktopAppVersion = process.env.REACT_APP_GET_VERSION_DESKTOPAPP
export const postDesktopAppLogs = process.env.REACT_APP_POST_LOG_DESKTOPAPP
export const postDesktopApp = process.env.REACT_APP_POST_DESKTOPAPP
export const putDesktopApp = process.env.REACT_APP_PUT_DESKTOPAPP
//#endregion

//#region EMPLOYEE
export const getTalent = process.env.REACT_APP_GET_TALENT
export const getTalentSkills = process.env.REACT_APP_GET_SKILLS_TALENT
export const getTalentLanguages = process.env.REACT_APP_GET_LANGUAGES_TALENT
export const getTalentProductTraining = process.env.REACT_APP_GET_PRODUCTTRAINING_TALENT
export const putTalent = process.env.REACT_APP_PUT_TALENT
//#endregion

//#region LOGGER
export const getLogger = process.env.REACT_APP_GET_LOGGER
export const postLogger = process.env.REACT_APP_POST_LOGGER
//#endregion

//#region NOTIFICATION
export const getNotification = process.env.REACT_APP_GET_NOTIFICATION
export const putNotification = process.env.REACT_APP_PUT_NOTIFICATION
export const deleteNotification = process.env.REACT_APP_DELETE_NOTIFICATION
//#endregion

//#region PAYMENTS
export const getPayments = process.env.REACT_APP_GET_PAYMENTS
export const getPaymentsUrl = process.env.REACT_APP_GET_URL_PAYMENTS
export const getPaymentsHistorical = process.env.REACT_APP_GET_HISTORICAL_PAYMENTS
export const postPaymentsUserRate = process.env.REACT_APP_POST_USER_RATE_PAYMENTS
export const postPaymentsPayment = process.env.REACT_APP_POST_PAYMENT_PAYMENTS
export const putPayments = process.env.REACT_APP_PUT_PAYMENTS
//#endregion

//#region PROJECTS
export const getProjects = process.env.REACT_APP_GET_PROJECTS
export const getProjectForAgent = process.env.REACT_APP_GET_FORAGENT_PROJECTS
export const getProjectsAgentsForProject = process.env.REACT_APP_GET_FORPROJECT_PROJECTS
export const postProjects = process.env.REACT_APP_POST_PROJECTS
export const postProjectsAssignament = process.env.REACT_APP_POST_ASSIGNAGENT_PROJECTS
export const postProjectsRemoveAgent = process.env.REACT_APP_POST_REMOVEAGENT_PROJECTS
export const postProjectsWeekReport = process.env.REACT_APP_POST_WEEKREPORT_PROJECTS
export const putProjects = process.env.REACT_APP_PUT_PROJECTS
//#endregion

//#region REPORTS
export const getReports = process.env.REACT_APP_GET_REPORTS
export const postReportsTrolleyReport = process.env.REACT_APP_POST_TROLLEY_REPORTS
export const postReportPayroll = process.env.REACT_APP_POST_PAYROLL_REPORTS
export const postReportUserWeekly = process.env.REACT_APP_POST_USER_WEEKLY_REPORTS
export const postReportsWeekly = process.env.REACT_APP_POST_WEEKLY_REPORTS
export const postReportsDaily = process.env.REACT_APP_POST_DAILY_REPORTS
//#endregion

//#region REQUEST
export const getRequest = process.env.REACT_APP_GET_REQUEST
export const getRequestUser = process.env.REACT_APP_GET_USER_REQUEST
export const getRequestRejectReason = process.env.REACT_APP_GET_REJECTREASON_REQUEST
//#endregion

//#region REQUEST_MANUALTIME
export const getManualTime = process.env.REACT_APP_GET_USER_MANUALTIME
export const postManualTime = process.env.REACT_APP_POST_ADD_MANUALTIME
export const postManualTimeChange = process.env.REACT_APP_POST_CHANGE_MANUALTIME
export const postManualTimeGetRequests = process.env.REACT_APP_POST_GETREQUESTS_MANUALTIME
export const deleteManualTime = process.env.REACT_APP_DELETE_REQUEST_MANUALTIME
export const deleteManualTimeCancel = process.env.REACT_APP_DELETE_CANCELREQUEST_MANUALTIME
//#endregion 

//#region REQUEST_OVERTIME
export const getOvertime = process.env.REACT_APP_GET_USER_OVERTIME
export const postOvertime = process.env.REACT_APP_POST_ADD_OVERTIME
export const postOvertimeChange = process.env.REACT_APP_POST_CHANGE_OVERTIME
export const postOvertimeGetRequests = process.env.REACT_APP_POST_GETREQUESTS_OVERTIME
export const deleteOvertime = process.env.REACT_APP_DELETE_REQUEST_OVERTIME
export const deleteOvertimeCancel = process.env.REACT_APP_DELETE_CANCELREQUEST_OVERTIMEE
//#endregion

//#region SETTINGS
export const getSettings = process.env.REACT_APP_GET_SETTINGS
export const postSettings = process.env.REACT_APP_POST_SETTINGS
//#endregion

//#region TRACKING_DATA
export const getTrackingDataScreenshots = process.env.REACT_APP_GET_SCREENSHOT_TRACKINGDATA
export const getTrackingDataWebcam = process.env.REACT_APP_GET_WEBCAM_TRACKINGDATA
export const getTrackingDataProfilePicture = process.env.REACT_APP_GET_PROFILEPICTURE_TRACKINGDATA
export const getTrackingData = process.env.REACT_APP_GET_TRACKINGDATA
export const getTrackingDataMonthly = process.env.REACT_APP_GET_MONTHLY_TRACKINGDATA
export const postTrackingData = process.env.REACT_APP_POST_TRACKINGDATA
//#endregion

//#region TRACKING_DATA_AUDIT
export const deleteTrackingDataAudit = process.env.REACT_APP_POST_DELETE_TRACKINGDATAAUDIT
export const putTrackingDataAudit = process.env.REACT_APP_PUT_TRACKINGDATAAUDIT
//#endregion

//#region USERS
export const getUsers = process.env.REACT_APP_GET_USERS
export const getUsersTeamMembers = process.env.REACT_APP_GET_TEAMMEMBERS_USERS
export const getUsersSearchUsers = process.env.REACT_APP_GET_SEARCH_USERS
export const getUsersAsync = process.env.REACT_APP_GET_ASYNC_USERS
export const getUsersEmploymentInfo = process.env.REACT_APP_GET_EMPLOYMENTINFO_USERS
export const getUsersManagers = process.env.REACT_APP_GET_MANAGERS_USERS
export const getUsersRole = process.env.REACT_APP_GET_ROLES_USERS
export const getUsersRate = process.env.REACT_APP_GET_RATE_USERS
export const getUserAll = process.env.REACT_APP_GET_ALL_USERS
export const postUsersLogin = process.env.REACT_APP_POST_USERS
export const postUserPhoto = process.env.REACT_APP_POST_PHOTO_USERS
export const postUserById = process.env.REACT_APP_POST_GETBYID_USERS
export const putUsers = process.env.REACT_APP_PUT_USERS
export const putUsersFirstTimeLogin = process.env.REACT_APP_PUT_LOGIN_USERS
export const putUsersPassword = process.env.REACT_APP_PUT_PASSWORD_USERS
export const putUsersRole = process.env.REACT_APP_PUT_ROLE_USERS
export const putUsersWebTracker = process.env.REACT_APP_PUT_WEBTRACKER_USERS
export const putUserWebToken = process.env.REACT_APP_PUT_WEBTOKEN
//#endregion

//#region WEB_TRACKER
export const postWebTrackerRemoveJob = process.env.REACT_APP_POST_REMOVEJOB_WEBTRACKER
export const postWebTrackerResumeJob = process.env.REACT_APP_POST_RESUMEJOB_WEBTRACKER
export const postWebTrackerStatus = process.env.REACT_APP_POST_STATUS_WEBTRACKER
//#endregion


//#region NOTIFICATION API

export const emailNotificationShift = process.env.REACT_APP_EMAIL_NOTIFICATION_SHIFT
export const emailNotificationTimeoff = process.env.REACT_APP_EMAIL_NOTIFICATION_TIMEOFF
export const emailNotificationTimeoffStatus = process.env.REACT_APP_EMAIL_NOTIFICATION_TIMEOFF_STATUS

export const notification = process.env.REACT_APP_NOTIFICATION
export const notificationExpo = process.env.REACT_APP_NOTIFICATION_EXPO
export const notificationFirebase = process.env.REACT_APP_NOTIFICATION_FIREBASE
export const notificationFirebaseTopics = process.env.REACT_APP_NOTIFICATION_FIREBASE_TOPICS

//#endregion