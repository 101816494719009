import DashboardMain from "../components/layout/main/DashboardMain";
function ManagerDashboard() {
  return (
    <div>
      <DashboardMain />
    </div>
  );
}

export default ManagerDashboard;
