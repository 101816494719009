
import DashboardManualTimeApproval from "../components/manager/teamDashboards/DashboardManualTimeApproval.js";

function ManagerManualTimeApproval() {
  return (
    <DashboardManualTimeApproval />
  );
}

export default ManagerManualTimeApproval;
