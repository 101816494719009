import { ReactSession } from 'react-client-session';
import axios from 'axios';
import { notificationBaseURL } from './env';
const qs = require('qs');

const config = {
  headers: {
      'Application': 'React',
      'Content-Type': 'application/json',
      'x-api-key': process.env.REACT_APP_API_KEY
  }
};

ReactSession.setStoreType("localStorage");

// GET
export const getRequest = async (endpoint, params = {}) => {
  try {
    const queryString = qs.stringify(params);
    const response = await axios.get(`${notificationBaseURL}${endpoint}?${queryString}`, config);
    return response.data;
  } catch (error) {
    console.error('GET request error:', error);
    throw error;
  }
};

// POST
export const postRequest = async (endpoint, body = {}) => {
  try {
    const response = await axios.post(`${notificationBaseURL}${endpoint}`, body, config);
    return response.data;
  } catch (error) {
    console.error('POST request error:', error);
    throw error;
  }
};

// PUT
export const putRequest = async (endpoint, id, body = {}) => {
  try {
    const response = await axios.put(`${notificationBaseURL}${endpoint}?id=${id}`, body, config);
    return response.data;
  } catch (error) {
    console.error('PUT request error:', error);
    throw error;
  }
};

// DELETE
export const deleteRequest = async (endpoint, id) => {
  try {
    const response = await axios.delete(`${notificationBaseURL}${endpoint}/${id}`, config);
    return response.data;
  } catch (error) {
    console.error('DELETE request error:', error);
    throw error;
  }
};
