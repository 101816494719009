import "./Header.css";
import Logo from "./Logo";
import Profile from "./Profile";
import DownloadButton from "../../elements/DownloadButton";
import { ReactSession } from 'react-client-session';
import Tracker from "../../elements/tracker/Tracker";
import Notifications from "../../elements/UiElements/Notifications";
import { useEffect, useState } from "react";
import { getRequest } from "../../api/notificationApi";
import { notification, putUsers } from "../../api/env";
import { put } from "../../api/apiCalls";
import { generateToken, messaging } from "../../../firebase";
import { onMessage } from "firebase/messaging";
import sound from '../../../sounds/notifiation.mp3';

function Header({ title, onOpenWorkforce }) {
  let user = ReactSession.get("User");
  let webToken = sessionStorage.getItem('webToken')
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    generateToken();

    onMessage(messaging, (payload) => {
      let message = {
        _id: payload.messageId,
        content:payload.notification.body,
        path: '',
        isRead: false
      }
      playNotificationSound();
      
      setMessages(prevMessages => [...prevMessages, message]);
    });
  })

  useEffect(() => {
    fetchData();
    updateUserToken(user.id, user.role, webToken);
  }, []);

  const fetchData = async () => {
    try {
      let keys = {
        email: user.username,
        read: false
      };
      let requests = await getRequest(`${notification}`, keys);
      setMessages(requests.data);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const playNotificationSound = () => {
    const audio = new Audio(sound);
    audio.play().catch(error => console.error("Erro ao reproduzir som:", error));
  };

  const updateUserToken = async (id, role, token) => {
    let keys = {
      UserId: id,
      Role: role,
      webToken: token
    };
    await put(`${putUsers}`, keys);
  };

  return (
    <div className="header">
      <div className="align-left">
        <Logo />
        <div className="pageName">{title}</div>
      </div>
      <div className="align-right">
        {user.webtracker_enabled && <Tracker />}
        <div className="download-button">
          {user.role !== 4 && !user.disabled && <DownloadButton />}
        </div>
        <Notifications messages={messages} />
        <Profile onOpenWorkforce={onOpenWorkforce} />
      </div>
    </div>
  );
}

export default Header;
