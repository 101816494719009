import { Route, Routes, Navigate } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import SignInPage from "./pages/SignInPage";
import EmployeeSummaryDashboard from "./pages/EmployeeSummaryDashboard";
import EmployeeDiary from "./pages/EmployeeDiary";
import EmployeeProfile from "./pages/EmployeeProfile";
import Requests from "./components/employee/profile/Requests";
import Payment from "./components/employee/profile/Payment";
import WorkInfo from "./components/employee/profile/WorkInfo";
import Audit from "./pages/Audit";
import ManagerDashboard from "./pages/ManagerDashboard";
import ManagerProjectsDashboard from './pages/ManagerProjectsDashboard';
import UserSummaryDashboard from "./pages/UserSummaryDashboard";
import UserDiary from "./pages/UserDiary";
import UsersRequest from "./pages/UsersRequests"
import ManagerPayrollFullDetails from "./pages/ManagerPayrollFullDetails";
import TeamMemberDashboard from "./pages/TeamMemberDashboard"
import React, { useEffect, useState } from 'react';
import AdminPersonalInfo  from "./pages/PersonalInfo";
import PersonalInfo from "./components/employee/profile/PersonalInfo";
import AdditionalPayments from "./pages/AdditionalPayments";
import ManagerManualTimeApproval from "./pages/ManagerManualTimeApproval";
import UserCompliance from "./pages/UserCompliance";
import TeamDashboard from './components/layout/main/TeamDashboard'
import { DefaultLayout } from "./components/layout/DefaultLayout";
import { ManagerLayout } from "./components/layout/ManagerLayout";
import { AdminLayout } from "./components/layout/AdminLayout";
import { CustomerLayout } from "./components/layout/CustomerLayout";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { ReactSession } from 'react-client-session'
import DashboardMain from "./components/layout/main/DashboardMain";
import DashboardManagerPayrollFullDetails from "./components/manager/teamDashboards/DashboardManagerPayrollFullDetails";
import AuditDetails from "./components/manager/dashboard/AuditDetails";
import AdditionalPaymentsPage from "./components/manager/dashboard/AdditionalPaymentsPage";
import UserSummary from "./components/manager/dashboard/UserSummary";
import UserPersonalInfo from "./components/manager/employeeprofile/UserPersonalInfo";
import UserDailyDiary from "./components/manager/dashboard/UserDailyDiary";
import UserRequest from "./components/manager/employeeprofile/UserRequest";
import DashboardManagerProjects from "./components/manager/teamDashboards/DashboardManagerProjects";
import DashboardManualTimeApproval from "./components/manager/teamDashboards/DashboardManualTimeApproval";
import UserCompliances from "./components/manager/employeeprofile/UserCompliances";
import AdminSettings from "./components/admin/AdminSettings";
import AdminAnnouncementsPage from "./components/admin/AdminAnnouncementsPage";
import AdminWebTrackerPage from "./components/admin/AdminWebTrackerPage";
import AdminComplianceSettingsPage from "./components/admin/AdminComplianceSettingsPage";
import TrolleySetup from "./components/admin/TrolleySetup";
import UpdateRate from "./components/admin/UpdateRate";
import UserRole from "./components/admin/UserRole";
import AdminAdditionalPaymentsPage from "./components/admin/AdminAdditionalPaymentsPage";
import { AdminEmployeePage } from "./components/admin/AdminEmployeePage";
import WfmIframe from "./components/ui/WfmIframe";



export function Router(){
    const [user, setUser] = useState({})

    useEffect(() => {
        let userInSession = ReactSession.get('User')
        setUser(userInSession)
    }, [])
    return (
        <Routes>
            <Route path="/*" element={<Navigate to="/summary" />} />
            <Route path="/signin" element={<SignInPage />} />
            <Route path="/" element={<LandingPage />} />
            <Route path="*" element={<Navigate to="/" />} />

            {user !== undefined && user.role === 2 && (
                <Route path="/" element={<DefaultLayout />}>
                    <Route path="/workforce" element={<WfmIframe />} />
                    <Route path="/summary" element={<EmployeeSummaryDashboard />} />
                    <Route path="/diary" element={<EmployeeDiary />} />
                    <Route path="/profile" element={<EmployeeProfile />} />
                    <Route path="/profile/info" element={<PersonalInfo />} />
                    <Route path="/profile/requests" element={<Requests />}/>
                    <Route path="/profile/payment" element={<Payment />}/>
                    <Route path="/profile/work" element={<WorkInfo />} />
                </Route>
            )}

            {user !== undefined && user.role === 3 &&(
                <Route path="/" element={<ManagerLayout />}>
                    <Route path="/workforce" element={<WfmIframe />} />
                    <Route path="/summary" element={<EmployeeSummaryDashboard />} />
                    <Route path="/diary" element={<EmployeeDiary />} />
                    <Route path="/profile" element={<EmployeeProfile />} />
                    <Route path="/profile/info" element={<PersonalInfo />} />
                    <Route path="/profile/requests" element={<Requests />}/>
                    <Route path="/profile/payment" element={<Payment />}/>
                    <Route path="/profile/work" element={<WorkInfo />} />
                    <Route path="/manager" element={<DashboardMain />} />
                    <Route path="/manager/payrolldetails" element={<DashboardManagerPayrollFullDetails />} />
                    <Route path="/manager/audit" element={<AuditDetails />} />
                    <Route path="/manager/additional-payments" element={<AdditionalPaymentsPage />} />
                    <Route path="/manager/summary/:employeeId" element={<UserSummary assignedProjects="2" />} />
                    <Route path="/manager/personalInfo/:employeeId" element={<UserPersonalInfo />} />
                    <Route path="/manager/diary/:employeeId" element={<UserDailyDiary />} />
                    <Route path="/manager/user-requests/:employeeId" element={<UserRequest />} />
                    <Route path="/manager/projects" element={<DashboardManagerProjects />} />
                    <Route path="/manager/manual-time-approval" element={<DashboardManualTimeApproval />} />
                    <Route path="/manager/compliance/:userId" element={<UserCompliances />}/>
                </Route>
            )}

            {user !== undefined && user.role === 1 &&(
                <Route path="/" element={<AdminLayout />}>
                    <Route path="/workforce" element={<WfmIframe />} />
                    <Route path="/summary" element={<EmployeeSummaryDashboard />} />
                    <Route path="/diary" element={<EmployeeDiary />} />
                    <Route path="/profile" element={<EmployeeProfile />} />
                    <Route path="/profile/info" element={<PersonalInfo />} />
                    <Route path="/profile/requests" element={<Requests />}/>
                    <Route path="/profile/payment" element={<Payment />}/>
                    <Route path="/profile/work" element={<WorkInfo />} />
                    <Route path="/manager" element={<ManagerDashboard />} />
                    <Route path="/manager/payrolldetails" element={<ManagerPayrollFullDetails />} />
                    <Route path="/manager/audit" element={<Audit />} />
                    <Route path="/manager/additional-payments" element={<AdditionalPayments />} />
                    <Route path="/manager/summary/:employeeId" element={<UserSummaryDashboard />} />
                    <Route path="/manager/personalInfo/:employeeId" element={<AdminPersonalInfo />} />
                    <Route path="/manager/diary/:employeeId" element={<UserDiary />} />
                    <Route path="/manager/user-requests/:employeeId" element={<UsersRequest />} />
                    <Route path="/manager/projects" element={<ManagerProjectsDashboard />} />
                    <Route path="/manager/manual-time-approval" element={<ManagerManualTimeApproval />} />
                    <Route path="/manager/compliance/:userId" element={<UserCompliance />}/>
                    <Route path="/settings/sso-setup" element={<AdminSettings />} />
                    <Route path="/settings/announcements" element={<AdminAnnouncementsPage />} />
                    <Route path="/settings/web-tracker" element={<AdminWebTrackerPage />} />
                    <Route path="/settings/compliance-settings" element={<AdminComplianceSettingsPage />} />
                    <Route path="/user-settings/trolley-report" element={<TrolleySetup />} />
                    <Route path="/user-settings/update-rate" element={<UpdateRate />} />
                    <Route path="/user-settings/user-role" element={<UserRole />} />
                    <Route path="/user-settings/additional-payments" element={<AdminAdditionalPaymentsPage />} />
                    <Route path="/user-settings/talent-pool" element={<AdminEmployeePage />} />
                </Route>
            )}

            {user !== undefined && user.role === 4 &&(
                <Route path="/" element={<CustomerLayout />}>
                    <Route path="/summary" element={<EmployeeSummaryDashboard />} />
                    <Route path="/diary" element={<EmployeeDiary />} />
                    <Route path="/profile" element={<EmployeeProfile />} />
                    <Route path="/profile/info" element={<PersonalInfo />} />
                    <Route path="/profile/requests" element={<Requests />}/>
                    <Route path="/profile/payment" element={<Payment />}/>
                    <Route path="/profile/work" element={<WorkInfo />} />
                    <Route path="/team" element={<TeamDashboard />} /> 
                    <Route path="/team/dashboard/:employeeId" element={<TeamMemberDashboard />} />
                </Route>
            )}


        </Routes>
    )
}